export default [
  {
    path: '/product-categories',
    name: 'product-categories-index',
    component: () => import('@/views/categories/product/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/product-categories/create',
    name: 'product-categories-store',
    component: () => import('@/views/categories/product/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'product-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/product-categories/edit/:id',
    name: 'product-categories-update',
    component: () => import('@/views/categories/product/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'product-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },

  {
    path: '/post-categories',
    name: 'post-categories-index',
    component: () => import('@/views/categories/post/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/post-categories/create',
    name: 'post-categories-store',
    component: () => import('@/views/categories/post/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'post-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/post-categories/edit/:id',
    name: 'post-categories-update',
    component: () => import('@/views/categories/post/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'post-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },

  {
    path: '/attribute-categories',
    name: 'attribute-categories-index',
    component: () => import('@/views/categories/attribute/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/attribute-categories/create',
    name: 'attribute-categories-store',
    component: () => import('@/views/categories/attribute/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'attribute-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/attribute-categories/edit/:id',
    name: 'attribute-categories-update',
    component: () => import('@/views/categories/attribute/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'attribute-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },

  {
    path: '/service-categories',
    name: 'service-categories-index',
    component: () => import('@/views/categories/service/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/service-categories/create',
    name: 'service-categories-store',
    component: () => import('@/views/categories/service/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'service-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/service-categories/edit/:id',
    name: 'service-categories-update',
    component: () => import('@/views/categories/service/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'service-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },

  {
    path: '/sale-categories',
    name: 'sale-categories-index',
    component: () => import('@/views/categories/sale/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/sale-categories/create',
    name: 'sale-categories-store',
    component: () => import('@/views/categories/sale/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'sale-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/sale-categories/edit/:id',
    name: 'sale-categories-update',
    component: () => import('@/views/categories/sale/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.categories',
      breadcrumb: [
        {
          text: 'admin.navigation.categories',
          to: { name: 'sale-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },
]
