import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'

const axiosIns = axios.create({
  baseURL: process.env.VUE_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const { response: { status, data } } = error

    if (status === 422) {
      store.dispatch('validation/setErrors', data.errors)
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
